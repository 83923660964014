.underlined {
  border-bottom-color: gray;
  border-bottom-style: solid;
  border-bottom-width: thin;
}

div#body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: scroll;
}

div#footer {
  display: flex;
  border-top-color: lightgray;
  border-top-style: solid;
  border-top-width: thin;
  height: 1.5em;
}

div#container {
  display: flex;
  height: 100%;
  width: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  flex-direction: column;
}

div#header {}

div#menu {}
.right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
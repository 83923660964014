.bm-burger-button {
    position: fixed;
    width: 2em;
    height: 2em;
    left: 0.5em;
    top: 1em;
}

.bm-burger-bars {
    background: #373a47;
}

.bm-menu {
    background: #373a47;
}

.bm-morph-shape {
    fill: #373a47;
}

.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

.bm-item {
    display: inline-block;
}

.bm-item:last-child {
    margin-bottom: 1em;
}

.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

a.burgerMenuLink {
    color: white;
}

a.burgerMenuLink:hover {
    color: orange;
}

#burger-menu-title {
    font-size: 1.5em;
    border: none;
    margin: 0 0 0.5em 0;
}

div.burger-menu-separator {
    margin: 1em 0em 1em 0em;
    background-color: white;
    height: 1px;
    width: 90%;
}

div:focus {
    outline: none !important;
}

/*
CSS rules for the antd menu included in the burger menu 
*/
.bm-menu .ant-menu {
    color: white;
    background: none;
}

.bm-menu .ant-menu .ant-menu-item {
    color: white;
    background: none;
    margin: 0;
    line-height: 25px;
    height: 25px;
}

.bm-menu .ant-menu .ant-menu-item:hover {
    color: orange;
}

.bm-menu .ant-menu .ant-menu-submenu-title {
    margin: 0;
}

.bm-menu .ant-menu .ant-menu-submenu-title:hover {
    color: orange;
}

.bm-menu .ant-menu .ant-menu-sub {
    background: none;
}

.shoppinglist-header {
    width: 100%;
    background-color: #ef7b2a;
    height: 150px;
    text-align: center;
}

.shoppinglist-title {
    font-size: 3.75rem;
    color: white;
    font-family: 'Lobster', cursive, 'Helvetica Neue', Arial, sans-serif;
    vertical-align: middle;
    padding: 2%;
}

.productItem {
    font-family: 'Lobster', cursive, 'Helvetica Neue', Arial, sans-serif;
    font-size: 1.2rem;
}

.shopping-list-divider {
    margin: 12px 0 24px 0;
}

.shoppinglist-options-box {
    margin: 24px 7% 5px 7%;
    float: right;
}

.shoppinglist-options {
    display: inline-block;
    padding-left: 20px;
    cursor: pointer;
}

.shoppinglist-box {
    border: 1px solid #e8e8e8;
    border-width: 1px 0px 0px 0px;
}

.delete-product {
    font-size: 1.2rem;
    cursor: pointer;
    color: #585858;
}

.delete-product:hover, .shoppinglist-options:hover {
    color: #ef7b2a;
}

.shopping-servings-class-container {
    height: 12em;
    width: 100%;
    padding: 0.5em;
}

.shopping-servings-class-container .block {
    border: thin solid lightgray;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0.25em;
    border-radius: 1em;
    justify-content: space-between;
}

.shopping-servings-class-container .block:hover {
    box-shadow: 0 0 15px #F07B0B;
}

.shopping-servings-class-container .image-container {
    height: 7em;
    max-height: 7em;
    min-height: 7em;
    max-width: 7em;
    overflow: hidden;
    border-radius: 1em;
}

.shopping-servings-class-container .image {
    /* https://github.com/philipwalton/flexbugs/issues/225 */
    height: 100%;
    max-height: 100%;
    max-width: 250%;
    object-fit: cover;
    text-transform: capitalize;
}

.shopping-servings-class-container .text {
    height: 3em;
    overflow: hidden;
    font-weight: bold;
}
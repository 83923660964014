.orderconsumptions-text {
    padding: 0em 2em 0em 2em;
    text-align: justify;
    white-space: pre-line;
    font-size: large;
}

.order-item {
    padding-right: 2%;
    padding-left: 2%;
}

.order-item .title {
    font-size: medium;
}

.order-date {
    font-size: medium;
    font-weight: bold;
    padding-left: 3em;
}

.order-footer {
    justify-content: right;
    padding: 1em 4em 0;
}

/* antd: xs < 576px */
@media screen and (max-width: 575px) {
    .order-footer {
        justify-content: center;
        padding: 2em 0 0;
    }
}

.order-confirmconsumptions {
    background-color: #ef7b2a !important;
    border-color: #ef7b2a !important;
    border-radius: 0.5em;
}

.order-item .action-consumption {
    display: flex;
    align-items: center;
    font-weight: bold;
}

.order-item .minus {
    padding: 3px 10px;
    font-size: 20px;
    color: #585858;
}

.order-item .plus {
    padding: 3px 10px;
    font-size: 20px;
    color: #585858;
}

.order-item .consumption {
    width: 40px;
    height: 30px;
    margin: 0 10px;
    font-size: 18px;
    font-weight: bold;
    color: white;
    background-color: #EF7B10;
}

.servings-class-block {
    display: flex;
    justify-content: center;
    padding: 6px;
    border: thin solid lightgray;
    border-radius: 1em;
    margin: 0.5em;
}

.servings-class-block .image-and-desc {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-right: 20px;
}

.servings-class-block .image-container {
    font-size: 16px;
}

.servings-class-block .image {
    width:110px;
    height:90px;
    text-transform: capitalize;
}

.servings-class-block .consumed {
    text-align: center;
}

.servings-class-block .non-zero {
    font-size: 18px;
    border-style: solid;
    border-width: 1px;
    padding: 2px 10px;
}
img#logo {
    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 100%;
}

div#logo {
    height: 4em;
    padding: 1em 0em 0.5em 1em;
}
.orderlist-item {
    padding-right: 2%;
    padding-left: 2%;
}
.order-not-consumed {
    background: #fdf2e7;
}

.orderlist-item .avatar {
    color: #f56a00;
    background-color: #fde3cf;
}

.orderlist-item .title{
    font-size: medium;
}

.orderlist-item .details-button {
    background: #f4a358;
    color: white;
    font-weight: bold;
}

.orderlist-item .consumptions-button {
    color: #f56a00;
    border-color: #f56a00;
    font-weight: bold;
    margin: 0 1em;
}
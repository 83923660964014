.recommendations-text {
    padding: 0em 2em 2em 2em;
    text-align: justify;
    white-space: pre-line;
    font-size: large;
}

.servings-class-container {
    height: 20em;
    width: 100%;
    padding: 0.5em;
}

.servings-class-container .block {
    border: thin solid lightgray;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0.25em;
    border-radius: 1em;
    justify-content: space-between;
}

.servings-class-container .block:hover {
    box-shadow: 0 0 15px #F07B0B;
}

.servings-class-container .image-container {
    height: 15em;
    max-height: 15em;
    min-height: 15em;
    max-width: 15em;
    overflow: hidden;
    border-radius: 1em;
}

.servings-class-container .image {
    /* https://github.com/philipwalton/flexbugs/issues/225 */
    height: 100%;
    max-height: 100%;
    max-width: 250%;
    object-fit: cover;
    text-transform: capitalize;
}

.servings-class-container .text {
    height: 3em;
    overflow: hidden;
    font-weight: bold;
}
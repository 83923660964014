.page-bg {
    background: url("../assets/images/Preventomics_background_2.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

.page-bg-logged {
    background: none;
}


/** General Preventomics Styles **/


/* Styles for primary button */

.ant-btn-primary {
    background-color: #878787;
    border-color: #878787;
}

.ant-btn-primary:focus {
    background-color: #878787;
    border-color: #878787;
}

.ant-btn-primary:hover {
    background-color: #878787e3;
    border-color: #878787e3;
}

a {
    /* color: #EF7B10; */
    color: rgba(0, 0, 0, 0.65);
}

a:hover {
    color: #EF7B10;
}

.signup-form-button[disabled],
.signup-form-button[disabled]:hover,
.signup-form-button[disabled]:focus {
    opacity: 0.6;
    color: #fff;
    background-color: #878787;
    border-color: #878787;
}
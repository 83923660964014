.preventomics-recommendation {
  text-align: center;
  border: 3px solid Orange;
  font-size: 110%;
  padding: 1em;
  margin: 0 0 3em 0;
}

.preventomics-recommendation #remember {
  font-size: 1.5em;
  font-weight: bold;
}

.add-cart-button {
  background-color: #ef7b2a !important;
  border-color: #ef7b2a !important;
  border-radius: 0.5em;
  font-weight: bold;
  margin: 0em 0em 2em 0em;
}

div#productdetail {
  margin: 0em 0em 0em 0em;
}

div#productdetail-comercialinformation {
  padding: 1em 0em 0em 0em;
}

div#productdetail-moreinformation {
  margin: 1em 0em 0em 0em;
  padding: 0em 2em 0em 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: thin solid lightgray;
}

div#productdetail-navigation-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0em 3em 0em 3em;
}

div#productdetail-navigation-bar-arrow {
  height: 100%;
  display: flex;
  flex-direction: horizontal;
  align-items: center;
  justify-content: center;
  margin: 1em 1em 1em 1em;
}
div#instructions-container {
    padding: 0.5em 4em 0.5em 4em;
}

div#instructions-title {
    font-size: 1.1em;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

div#instructions-data {}
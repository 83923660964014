div#nutritioninformation {
    padding: 1em 0em 1em 0em;
}

div#nutritioninformation-title {
    font-size: 1.1em;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

div#nutritioninformation-data {
    padding: 0.5em 0.5em 0.5em 0.5em;
}

tr#nutritioninformation-data {
    border-top: thin solid lightgray;
}

td#nutritioninformation-data-field {
    font-weight: bold;
    padding: 0 2em;
}

td#nutritioninformation-data-value {
    text-align: right;
    font-size: 1.2em;
}
.metabolomics-text {
    padding: 0em 2em 2em 2em;
    text-align: justify;
    white-space: pre-line;
    font-size: large;
}

.itemTitle {
    margin: 0 0.5em;
    padding: 0 0.5em;
    font-size: larger;
    border: solid;
    border-width: thin;
}

.itemDescription {
    padding: 0 0.5em;
}

.cluster-text {
    padding: 0em 2em 2em 2em;
    white-space: pre-line;
    font-size: large;
}

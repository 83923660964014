div#appmenu {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #F07B0B;
}

div#appmenu>a {
    padding: 0.5em 2em 0.5em 2em;
    font-size: large;
    color: white;
}

div#appmenu>a:hover {
    text-decoration: underline;
}

div#appmenu>a.selected {
    background-color: #d37014;
}
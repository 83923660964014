div#subcategory-container {
    padding: 0.5em 0.5em 0.5em 0.5em;
    height: 15em;
}

div#subcategory {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-radius: 1em;
    border: thin solid gray;
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding: 0.5em 0.5em 0.5em 0.5em;
}

.subcategory-icon-size {
    font-size: 7em;
}

div#subcategory:hover {
    box-shadow: 0 0 15px #F07B0B;
}
.recommended-foods-text {
    padding: 0em 2em 2em 2em;
    text-align: justify;
    white-space: pre-line;
    font-size: large;
}

.plate-image {
    max-width: 100%;
    padding: 0 1em 2em;
}

.food-container {
    padding: 0.5em 0.5em 0.5em 0.5em;
    height: 15em;
}

.food-container .block {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-radius: 1em;
    border: thin solid gray;
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding: 0.5em 0.5em 0.5em 0.5em;
}

.food-container .block:hover {
    box-shadow: 0 0 15px #F07B0B;
}

.food-container .image {
    width: 50%;
    height: 50%;
    text-transform: capitalize;
}
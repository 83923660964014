div#product-container {
    height: 20em;
    width: 100%;
    padding: 0.5em;
}

div#product-border {
    border: thin solid lightgray;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0.25em;
    border-radius: 1em;
    justify-content: space-between;
}

div#product-border:hover {
    box-shadow: 0 0 15px #F07B0B;
}

div#product-image-container {
    max-height: 15em;
    max-width: 15em;
    overflow: hidden;
    border-radius: 1em;
}

img#product-image {
    /* https://github.com/philipwalton/flexbugs/issues/225 */
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
}

span#product-text {
    height: 3em;
    overflow: hidden;
}
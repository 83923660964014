div#error {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.icon-size {
    font-size: 6em;
    margin: 0em 0em 0.5em 0em;
}
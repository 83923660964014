.container {
    padding: 0.5em;
    height: 15em;
}

.box {
    width: 100%;
    height: 100%;
    padding: 0.5em;
    border: thin solid lightgray;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 1em;
    justify-content: space-around;
}

.box:hover {
    box-shadow: 0 0 15px #F07B0B;
}

.box .image {
    height: 60%;
    width: 60%;
}

.box .text {
    text-align: center;
}
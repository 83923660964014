.profileAvatar {
    margin-left: 3em;
}

.restrictionsText {
    font-size: large;
    margin-bottom: 0.5em;
}    

.profileText {
    font-size: large;
    margin-bottom: 2em;
}    

/* antd: xs < 576px */
@media screen and (max-width: 575px) {
    .profileAvatar {
        margin-bottom: 2em;
    }
    .profileText {
        margin-left: 2em;
    }    
}

.resultsText {
    font-size: large;
    margin-bottom: 2em;
    margin-left: 2em;
}

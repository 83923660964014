div#comercialinformation-title {
    display: flex;
    justify-content: center;
    font-size: 1.5em;
    padding: 0em 0em 1em 0em;
}

.comercialinformation-centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

div#comercialinformation-image-container {
    height: 30em;
    width: 100%;
    overflow: hidden;
    margin: 0em 0.5em 0em 0em;
    display: flex;
    justify-content: center;
    flex-direction: horizontal;
    align-items: center;
}

img#comercialinformation-image {
    width: auto;
    height: auto;
    max-width: 30em;
    max-height: 30em;
    border: thin solid lightgray;
    border-radius: 1em;
}
div#products-carousel-product-content {
    height: 10em;
    width: 10em;
    overflow: hidden;
    border: thin solid lightgray;
    border-radius: 1em;
    margin: 0em 0.5em 0em 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
}

img#products-carousel-product-content {
    width: auto;
    height: auto;
    max-width: 10em;
    max-height: 10em;
}

div#products-carousel-title {
    margin: 1em 2em 2em 2em;
    padding: 0em 3em 0em 3em;
    font-size: 1.1em;
    font-weight: bold;
    display: flex;
    justify-content: left;
    align-items: center;
    border-bottom: thin solid lightgray;
}

div#products-carousel-content {
    display: flex;
    flex-direction: row;
    overflow: auto;
    margin: 1em 2em 2em 2em;
}

div#products-carousel {
    margin: 4em 0em 1em 0em;
    padding: 0em 2em 0em 2em;
}